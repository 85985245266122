body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /** Set this to hidden to prevent main body
  bouncing when reaching the overflowed area **/
  overflow: hidden;

  /** Disable text highlighting when user taps
  and hold the screen **/
  user-select: none;

  /**
   * Disable the horizontal swipe back and
   * vertical swipe to exit fullscreen mode on browsers.
   */
  overscroll-behavior: none;

  /**
   * Disables the orange highlight on input boxes!
   */
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   }
  :focus {
    outline: 0;
  }
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
